import React from 'react'
import { graphql, Link } from 'gatsby';
import SEO from '../components/seo'
import styled from '@emotion/styled'


import { skyBlue, oceanBlue } from '../constants/theme';

const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

const BlogBlockStyle = styled.div`
  background-color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  margin-top: 48px;

  .grid {
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @media (max-width: ${breakpoints.l}px){
      grid-template-columns: repeat(2, 1fr);
    }


    @media (max-width: ${breakpoints.s}px){
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .blog-post {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    color: ${oceanBlue};
    padding: 20px 20px 10px 20px;
    background-color: #ECF2F7;
  }

  p {
    font-size: 14px;
    font-family: 'HKNova-Regular';
    letter-spacing: 0.1rem;
    line-height: 1.4;

    a {
      color: ${skyBlue};
    }
  }

  h3 {
    letter-spacing: 0.350rem;
    margin-top: 0;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 21px;

  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    margin-top: 0;
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  img {
    border-radius: 100%;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }

  }
`;


const BlogBlock = ({articles}) => 
  <BlogBlockStyle>
    <h3>Copper Compression</h3>
    <h1>Blog Updates</h1>
    <div className="grid">
      {articles.map(post => { 

        const filteredUrl = post.node.url.replace('http://www.coppercompression.com/blogs/news/','/blog/');

        return (
        <Link to={filteredUrl} className="blog-post" key={ post.node.id }>
          <h2>{post.node.title}</h2>
          <p dangerouslySetInnerHTML={{__html: post.node.excerptHtml}} />
          <h3>{post.node.publishedAt}</h3>
        </Link>
        )} 
       )}
    </div>
  </BlogBlockStyle>

const BlogIndexPage = ({data}) => (
  <>
    <SEO title="Blog" keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />

    <BlogBlock articles={data.allShopifyArticle.edges}/>

  </>
)

export const query = graphql`
  query BlogIndexPageQuery {
    allShopifyArticle(sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          id
          excerptHtml
          title
          url
          publishedAt(formatString: "MMMM Do YYYY")
        }
      }
    }
  }
`



export default BlogIndexPage
